import styled from "styled-components";

export const MapBlockWrapper = styled.div`
	padding: 25px 0px;
	background: rgba(122, 130, 138, 0.7);
	display: grid;
	grid-template-columns: 66.66% 33.33%;
	width: 100%;

	margin: 0 auto;

	color: white;

	@media screen and (max-width: 895px) {
		display: flex;
		flex-direction: column;
	}
`;
