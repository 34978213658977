import React from "react";
import { Link } from "gatsby";
import { ToesWrapper, Toe } from "./style";

const FooterItems = () => {
	return (
		<ToesWrapper className="footbar">
			<Toe>
				<i class="fa-brands fa-creative-commons-by"> </i>
				<i class="fa-brands fa-creative-commons-sa"> RLF Retreats</i>
			</Toe>
			<Toe>
				<Link to="https://www.facebook.com/bigrockcandymt">
					<i class="fa-brands fa-facebook"></i>
				</Link>
			</Toe>
			<Toe>
				<Link to="https://instagram.com/bigrockcandymtn">
					<i class="fa-brands fa-instagram"></i>
				</Link>
			</Toe>
		</ToesWrapper>
	);
};

export default FooterItems;
