import styled from "styled-components";

export const ToesWrapper = styled.div`
	width: 1200px;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;

	width: 100%;
`;

export const Toe = styled.div`
	margin: auto 0;
	padding: 0 30px;
	line-height: 25px;
	justify-content: center;
	align-items: center;

	.fa-creative-commons-by {
		color: #fff;
		font-size: 1.5rem;
	}

	.fa-creative-commons-sa {
		color: #fff;
		font-size: 1.5rem;
	}

	.fa-facebook {
		color: #fff;
		font-size: 2rem;
	}

	.fa-instagram {
		color: #fff;
		font-size: 2rem;
	}
`;
