import React from "react";
import { HeroWrapper, HeadingWrapper, Heading, SubHeading } from "./style";
import { BgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const Hero = ({
	heading,
	subHeading,
	backgroundImage,
	needsCampSpotLink,
}) => {
	const pluginImage = getImage(backgroundImage);
	return (
		<HeroWrapper>
			<BgImage image={pluginImage}>
				<HeadingWrapper>
					{!needsCampSpotLink ? (
						<div>
							<Heading>{heading}</Heading>
							<SubHeading>{subHeading}</SubHeading>
						</div>
					) : (
						<div>
							<Heading>
								<Link
									to={`https://www.campspot.com/book/bigrockcandymt`}
								>
									{heading}
								</Link>
							</Heading>
						</div>
					)}
					<div className="call">
						<h2>Call Us At 417-932-1223</h2>
					</div>
				</HeadingWrapper>
			</BgImage>
		</HeroWrapper>
	);
};
