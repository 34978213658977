import styled from "styled-components";

export const HeroWrapper = styled.div`
	width: 100%;
	height: 50vh;
	> div:first-child {
		height: 100%;
	}
`;

export const HeadingWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0.4);
	background-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0.8),
		rgba(0, 0, 0, 0) 30%,
		rgba(0, 0, 0, 0.8) 130%
	);
	> div {
		margin: auto;
		padding: 16px;
		max-width: 1000px;
		text-align: center;
		line-height: 1.5;
		cursor: default;
	}
	a {
		margin: auto;
		padding: 16px;
		max-width: 1000px;
		text-align: center;
		line-height: 1.5;
		text-decoration: none;
		cursor: pointer;
		color: white;
		&:hover {
			color: #999;
		}
	}
	.call {
		width: 100%;
		height: 30px;
		padding: 5px;
		margin: 5px;
		color: white;
		text-align: right;
		position: absolute;
		right: 30px;
		bottom: 30px;
	}
	@media screen and (max-width: 895px) {
		.call {
			width: 100%;
			height: 30px;
			padding: 5px;
			margin: 5px;
			color: white;
			text-align: center;
			right: 0px;
		}
	}
`;

export const Heading = styled.div`
	color: #dbdbdb;
	margin: auto;
	font-size: 40px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;
`;

export const SubHeading = styled.div`
	color: #dbdbdb;
	max-width: 500px;
	margin: 0 auto;
`;
