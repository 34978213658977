import styled from "styled-components";

export const HeaderWrapper = styled.header`
	background-color: black;
	width: 100%;
	color: white;
`;

export const HeaderInner = styled.div`
	height: 60px;
	max-width: 95vw;
	margin: 0;
	display: flex;
	justify-content: center;
`;
