import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { MenuWrapper, MenuItem, SubMenuItemWrapper } from "./style";

const Menu = () => {
	const result = useStaticQuery(graphql`
		fragment menuItemData on ContentfulMenuItem {
			id
			label
			page {
				slug
			}
		}

		query MenuQuery {
			contentfulMenu {
				menuItems {
					...menuItemData
					subMenuItems {
						...menuItemData
					}
				}
			}
		}
	`);

	const [click, setClick] = useState(false);
	const [dropdown, setDropdown] = useState(false);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	const onMouseEnter = () => {
		if (window.innerWidth < 960) {
			setDropdown(false);
		} else {
			setDropdown(true);
		}
	};

	const onMouseLeave = () => {
		if (window.innerWidth < 960) {
			setDropdown(false);
		} else {
			setDropdown(false);
		}
	};

	return (
		<MenuWrapper className="navbar">
			<div className="menu-icon" onClick={handleClick}>
				<i className={click ? "fas fa-times" : "fas fa-bars"} />
			</div>
			<ul className={click ? "nav-menu active" : "nav-menu"}>
				{result.contentfulMenu.menuItems.map((menuItem) => (
					<MenuItem key={menuItem.id} className="nav-item">
						{!menuItem.subMenuItems ? (
							<Link
								className={"slug" + menuItem.page.slug}
								to={`/${menuItem.page.slug}`}
							>
								{menuItem.label}
							</Link>
						) : (
							<SubMenuItemWrapper>
								<div>
									{menuItem.label}{" "}
									<i className="fas fa-caret-down" />
								</div>
								<div>
									{menuItem.subMenuItems?.map(
										(subMenuItem) => (
											<div key={subMenuItem.id}>
												<Link
													to={`/${subMenuItem.page.slug}`}
												>
													{subMenuItem.label}
												</Link>
											</div>
										)
									)}
								</div>
							</SubMenuItemWrapper>
						)}
					</MenuItem>
				))}
			</ul>
		</MenuWrapper>
	);
};

export default Menu;
