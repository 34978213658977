import styled from "styled-components";

export const MenuWrapper = styled.div`
	/*background: linear-gradient(
		90deg,
		rgb(83, 77, 77) 0%,
		rgb(15, 16, 17) 100%
	);*/
	width: 1200px;

	font-size: 1.2rem;

	> .nav-menu {
		display: grid;
		grid-template-columns: repeat(5, auto);
		grid-auto-columns: max-content;
		width: 100%;
	}

	.menu-icon {
		display: none;
	}

	@media screen and (max-width: 895px) {
		.nav-menu {
			display: none;
			width: 100%;
			height: 90vh;
			position: absolute;
			top: 40px;
			right: 10px;
			opacity: 1;
			transition: all 0.5s ease;
		}

		.nav-menu.active {
			display: flex;
			flex-direction: column;
			background: #242222;
			left: 0;
			opacity: 1;
			transition: all 0.5s ease;
			z-index: 1;
		}
		.menu-icon {
			display: block;
			position: absolute;
			top: -10px;
			left: 50px;
			transform: translate(-100%, 60%);
			font-size: 1.8rem;
			cursor: pointer;
		}

		.fa-times {
			color: #fff;
			font-size: 2rem;
		}
	}
`;

export const MenuItem = styled.div`
	margin: auto 0;
	padding: 0 16px;
	line-height: 25px;
	justify-content: center;
	align-items: center;
	a {
		color: white;
		&:hover {
			color: #999;
		}
	}

	@media screen and (max-width: 895px) {
		.nav-links {
			text-align: center;
			padding: 2rem;
			width: 100%;
			display: table;
		}

		.nav-links:hover {
			background-color: #1888ff;
			border-radius: 0;
		}
	}
`;

export const SubMenuItemWrapper = styled.div`
	position: relative;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		> div:last-child {
			display: block;
		}
	}
	> div:last-child {
		display: none;
		position: absolute;
		z-index: 1;
		top: 25px;
		background: black;
		white-space: nowrap;
		box-shadow: 2px 2px 2px black;
		padding: 8px;
		border: 1px solid #999;
		> div {
			line-height: 1;
			padding: 8px 16px;
		}
	}
`;

/*	margin: auto;
	display: flex;
*/

/*
  display: flex;
  align-items: center;
*/
