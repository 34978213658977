import styled from "styled-components";

export const InfoBlockGroupWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@media screen and (max-width: 895px) {
		display: flex;
		flex-direction: column;

		width: 100%;
	}
`;

export const InfoBlock = styled.div`
	flex: 0 1 ${({ numBlocks }) => 100 / numBlocks + "%"};
	text-align: center;
`;

export const InfoBlockInner = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	background: ${({ isEnlarged }) => (isEnlarged ? "#3f3f3f" : "#1c1c1c")};
	color: #dbdbdb;

	padding: 10px 10px 30px 10px;

	position: relative;
	height: 100%;
	box-sizing: border-box;
	text-align: center;
	ul {
		text-align: left;
		margin: 0 auto;
	}
	.infoLink {
		margin-top: auto;
		align-self: center;
		justify-content: center;
		border: 2px solid #999;
		padding: 10px 10px 10px 10px;
		border-radius: 10px;

		width: 40;
		box-sizing: border-box;
		color: white;
		text-decoration: none;
		&:hover {
			color: #999;
		}
	}
	@media screen and (max-width: 895px) {
		height: auto;
		overflow: hidden;
	}
`;

export const InfoBlockImageWrapper = styled.div`
	width: auto;
	margin: 15px auto 15px auto;
	/*border-radius: 1000px;*/
	justify-content: center;
	display: block;
	object-fit: contain;

	.img {
		object-fit: contain;
		width: 400px;
		aspect-ratio: 400/600;
	}
	@media screen and (max-width: 1300px) {
		.img {
			width: 300px;
			aspect-ratio: 400/600;
		}
	}
	@media screen and (max-width: 950px) {
		.img {
			width: 200px;
			aspect-ratio: 400/600;
		}
	}
	@media screen and (max-width: 895px) {
		width: auto;
		margin: 15px auto 15px auto;
		/*border-radius: 1000px;*/
		justify-content: center;
		display: block;
		object-fit: cover;
		overflow: hidden;
		.img {
			width: 400px;
			aspect-ratio: 400/600;
		}
	}
`;

export const AboutUsInfoBlockInner = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	background: ${({ isEnlarged }) => (isEnlarged ? "#3f3f3f" : "#1c1c1c")};
	color: #dbdbdb;
	margin: 50px 50px 0px 50px;
	padding: 50px 0px 0px 0px;
	border-radius: 90px;
	position: relative;
	height: 95%;
	box-sizing: border-box;
	text-align: center;
	background: linear-gradient(
		to bottom,
		rgba(1, 1, 1, 0.3),
		rgba(1, 1, 1, 0.5)
	);
	color: black;

	@media screen and (max-width: 895px) {
		height: auto;
		overflow: hidden;
	}
`;

export const AboutUsInfoBlockImageWrapper = styled.div`
	width: auto;
	margin: 15px auto 15px auto;

	justify-content: center;
	display: block;
	object-fit: contain;

	.img {
		border-radius: 25px;
		object-fit: contain;
		width: 400px;
		aspect-ratio: 400/600;
	}
	@media screen and (max-width: 1300px) {
		.img {
			width: 300px;
			aspect-ratio: 400/600;
		}
	}
	@media screen and (max-width: 950px) {
		.img {
			width: 300px;
			aspect-ratio: 400/600;
		}
	}
	@media screen and (max-width: 895px) {
		width: auto;
		margin: 15px auto 15px auto;
		/*border-radius: 1000px;*/
		justify-content: center;
		display: block;
		object-fit: cover;
		overflow: hidden;
		.img {
			width: 400px;
			aspect-ratio: 400/600;
		}
	}
	@media screen and (max-width: 700px) {
		width: auto;
		margin: 15px auto 15px auto;
		/*border-radius: 1000px;*/
		justify-content: center;
		display: block;
		object-fit: cover;
		overflow: hidden;
		.img {
			width: 300px;
			aspect-ratio: 400/600;
		}
	}
`;
