import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Hero } from "components";
import { InfoBlockGroup } from "../InfoBlockGroup";
import { GatsbyImage } from "gatsby-plugin-image";
import { Wrapper, ImageWrapper } from "./style";
import { MapBlock } from "../MapBlock";
import { InfoRow } from "../InfoRow";

export const RichText = ({ raw, page, references = [] }) => {
	const referencesMap = {};
	references.forEach((reference) => {
		referencesMap[reference.contentful_id] = reference;
	});

	const options = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const data = referencesMap[node.data.target.sys.id];

				return (
					<ImageWrapper>
						<GatsbyImage
							alt={data.title}
							image={data.gatsbyImageData}
						/>
					</ImageWrapper>
				);
			},
			[BLOCKS.EMBEDDED_ENTRY]: (node) => {
				const data = referencesMap[node.data.target.sys.id];

				switch (data.__typename) {
					case "ContentfulHero":
						return (
							<Hero
								heading={data.heading}
								subHeading={data.subHeading}
								needsCampSpotLink={data.campSpotLink}
								backgroundImage={
									data.backgroundImage.gatsbyImageData
								}
							/>
						);
					case "ContentfulInfoBlockGroup":
						return (
							<InfoBlockGroup
								blocks={data.infoBlocks}
								page={page.title}
							></InfoBlockGroup>
						);
					case "ContentfulMapBlock":
						return (
							<MapBlock
								imageTitle={data.title}
								image={data.mapImage.gatsbyImageData}
								description={data.description}
							/>
						);
					case "ContentfulInfoRow":
						return (
							<InfoRow
								imageTitle={data.title}
								image={data.image.gatsbyImageData}
								description={data.description}
							/>
						);
					default:
						return null;
				}
			},
		},
	};
	return (
		<Wrapper>{documentToReactComponents(JSON.parse(raw), options)}</Wrapper>
	);
};
