import React from "react";
import { RichText } from "components";
import { GatsbyImage } from "gatsby-plugin-image";
import { InfoRowWrapper, InfoRowImageWrapper } from "./style";

export const InfoRow = ({ imageTitle, image, description }) => {
	return (
		<InfoRowWrapper>
			<RichText raw={description.raw} />
			<InfoRowImageWrapper>
				<GatsbyImage className="image" alt={imageTitle} image={image} />
			</InfoRowImageWrapper>
		</InfoRowWrapper>
	);
};
