import styled from "styled-components";

export const FooterWrapper = styled.header`
	background-color: #3f3f3f;
	width: 100%;
	color: white;
`;

export const FooterInner = styled.div`
	height: 80px;
	max-width: 95vw;
	margin: 0;
	display: flex;
	justify-content: center;
`;
