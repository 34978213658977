import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import Header from "./Header";
import Footer from "./Footer";

const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    
  }
  h1{
    font-size: 2em;
  }
`;

export const Layout = ({ children }) => {
	return (
		<div>
			<Helmet>
				<script
					src="https://kit.fontawesome.com/ac6f60c282.js"
					crossorigin="anonymous"
				></script>
			</Helmet>

			<GlobalStyle />
			<Header />
			<section>{children}</section>
			<Footer />
		</div>
	);
};
