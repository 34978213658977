import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { CalendarWrapper } from "./style";
import { StaticImage } from "gatsby-plugin-image";

export const EventCalendar = ({ rawEvents = [] }) => {
	const calendarRef = useRef(null);
	const eventList = [];
	

	rawEvents.forEach((event) => {
		if (event.attachments != null) {
			eventList.push({
				id: event.id,
				title: event.summary,
				start: event.start.dateTime,
				end: event.end.dateTime,
				description: event.description,
				extendedProps: {
					imgUrl: event.attachments,
				},
			});
		} else {
			eventList.push({
				id: event.id,
				title: event.summary,
				description: event.description,
				start: event.start.dateTime,
				end: event.end.dateTime,
			});
		}
	});

	return (
		<CalendarWrapper>
			<FullCalendar
				plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
				initialView="dayGridMonth"
				selectable={true}
				events={eventList}
				eventContent={renderEventContent}
				eventClick={handleEventClick}
				ref={calendarRef}
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,listWeek",
				}}
			/>
		</CalendarWrapper>
	);

	function renderEventContent(eventInfo) {
		console.log(eventInfo.event.extendedProps.description)
		if (JSON.stringify(eventInfo.event.extendedProps) !== "{}") {
			//const image = getImage()
			
			return (
				<>
					<b>{eventInfo.event.title}</b>
					<br />
					<span>&nbsp;</span>
					<i>{eventInfo.event.extendedProps.description}</i>
					<br />
				</>
			);
		} else {
			return (
				<>
					<b>{eventInfo.event.title}</b>
					<br />
					
				</>
			);
		}
	}
	function handleEventClick(clickInfo) {
		let eventStartDate = calendarRef.current.calendar.getEventById(
			clickInfo.event._def.publicId
		);
		calendarRef.current.calendar.changeView(
			"listWeek",
			eventStartDate._instance.range.start
		);
	}
};
//("2019-08-12T10:30:00");
