import React from "react";
import { RichText } from "components";
import { GatsbyImage } from "gatsby-plugin-image";
import { MapBlockWrapper } from "./style";

export const MapBlock = ({ imageTitle, image, description }) => {
	return (
		<MapBlockWrapper>
			<GatsbyImage alt={imageTitle} image={image} />
			<RichText raw={description.raw} />
		</MapBlockWrapper>
	);
};
