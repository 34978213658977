import React from "react";
import { FooterInner, FooterWrapper } from "./style";
import FooterItems from "./FooterItems";

const Footer = () => {
	return (
		<FooterWrapper>
			<FooterInner>
				<FooterItems />
			</FooterInner>
		</FooterWrapper>
	);
};

export default Footer;
