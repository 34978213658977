import styled from "styled-components";

export const Wrapper = styled.div`
	> p,
	> h1,
	> h2,
	> h3,
	> h4,
	> h5,
	> h6 {
		display: grid;
		max-width: 1000px;
		margin: 30px auto;
		text-align: center;
	}
`;

export const ImageWrapper = styled.div`
	max-width: 1000px;
	max-height: 1000px;
	margin: 0 auto;
`;
