import styled from "styled-components";

export const InfoRowWrapper = styled.div`
	padding: 25px 0px 25px 0px;
	margin: 25px 0px;
	display: grid;
	grid-template-columns: 66.66% 33.33%;

	margin-top: 25px;
	background: rgba(122, 130, 138, 0.7);
	color: white;

	@media screen and (max-width: 895px) {
		display: flex;
		flex-direction: column;
	}
`;

export const InfoRowImageWrapper = styled.div`
	.image {
		width: 400px;
		height: auto;
	}
	@media screen and (max-width: 895px) {
		width: auto;
		margin: 15px auto 15px auto;
		/*border-radius: 1000px;*/
		justify-content: center;
		display: block;
		object-fit: cover;

		.image {
			width: 350px;
			height: 500px;
		}
	}
`;
