import React from "react";
import {
	InfoBlockGroupWrapper,
	InfoBlock,
	InfoBlockInner,
	InfoBlockImageWrapper,
	AboutUsInfoBlockInner,
	AboutUsInfoBlockImageWrapper,
} from "./style";
import { BgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { RichText } from "components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const InfoBlockGroup = ({ blocks, page }) => {
	var numInfoBlocks = blocks.length;
	return (
		<InfoBlockGroupWrapper>
			{blocks.map((infoBlock) => (
				<InfoBlock key={infoBlock.id} numBlocks={numInfoBlocks}>
					{page === "About Us" ? (
						<AboutUsInfoBlockInner isEnlarged={infoBlock.Enlarged}>
							<AboutUsInfoBlockImageWrapper>
								<GatsbyImage
									className="img"
									alt={infoBlock.highlightImage.title}
									image={
										infoBlock.highlightImage.gatsbyImageData
									}
								/>
							</AboutUsInfoBlockImageWrapper>

							<RichText raw={infoBlock.description.raw} />
						</AboutUsInfoBlockInner>
					) : (
						<InfoBlockInner isEnlarged={infoBlock.Enlarged}>
							<h2>{infoBlock.title}</h2>
							<InfoBlockImageWrapper>
								<GatsbyImage
									className="img"
									alt={infoBlock.highlightImage.title}
									image={
										infoBlock.highlightImage.gatsbyImageData
									}
								/>
							</InfoBlockImageWrapper>

							<RichText raw={infoBlock.description.raw} />

							{page === "Home" ? (
								<Link
									className="infoLink"
									to={`/${infoBlock.title
										.replace(/[\s;]+/g, "-")
										.toLowerCase()}`}
								>
									More Info
								</Link>
							) : (
								<div></div>
							)}
							{page === "Lodging" ? (
								<Link
									className="infoLink"
									to={`https://www.campspot.com/book/bigrockcandymt`}
								>
									Book Now!
								</Link>
							) : (
								<div></div>
							)}
							{infoBlock.ticketLink !== null ? (
								<Link
									className="infoLink"
									to={infoBlock.ticketLink}
								>
									Purchase Tickets!
								</Link>
							) : (
								<div></div>
							)}
						</InfoBlockInner>
					)}
				</InfoBlock>
			))}
		</InfoBlockGroupWrapper>
	);
};
